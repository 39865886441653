<template>
  <div class="moduleOne">
    <!-- 标题 -->
    <div class="title">
      <div class="title-t">关于融智云</div>
      <div class="title-b"></div>
    </div>
    <!-- 内容 -->
    <div class="introduce">
      <div class="introduce-text">
        <p>
          福州市融智云科技有限公司是一家集设计、研发、运维、服务于一体的高科技IT企业，主要致力于电子商务。以软件外包和人员驻点为主要业务的IT信息化公司。
          公司成立于2019年，总部位于福州高新区创新园，目前拥有员工20余人，研发人员占比高达80%。
          <template v-if="textP">
            福州市融智云科技有限公司富有朝气，团队90后占比
            居高,研发部也不泛多名资深程序员、软件工程师组成的专业队伍。公司整体技术是以Java开发为主，项目多采后端分离模式。平台研发、互联网技术、网站设计、APP开发等,
            截止目前，已获取计算机软件著作
            权登记证书17个。公司项目服务对象以政府国有企业居多，包括福州著名的福诺、福富、新大陆、教育报、卫生报、江西农工党等。典型项目案例包括：福建省中国移动文档管理系统、江西农工党智慧农工管理平台、新大陆教育1+X证书推广平台等。要面向中小企业，助力于中小企业快速发展。我们始终坚持诚信和让利于客户，坚持用自己的服务打动客户。
          </template>
          <span v-if="textP" class="textMore" @click="getMoreText(false)"
            >收起>></span
          >
          <span v-else class="textMore" @click="getMoreText(true)">更多>></span>
        </p>
      </div>

      <div class="introduce-geto">
        <div class="introduce-item" @click="getOpenItemChilden(21)">
          <div
            :class="
              textEffect ? 'introduce-item-icon slide-in-right' : 'disnone'
            "
          >
            <img src="@/assets/image/home/gsjs.png" alt="" />
          </div>
          <div
            :class="
              textEffect ? 'introduce-item-text slide-in-right' : 'disnone'
            "
          >
            <div class="introduce-item-text-z">公司介绍</div>
            <div class="introduce-item-text-e">COMPANY INTRODUCTION</div>
          </div>
        </div>
        <div class="introduce-item" @click="getOpenItemChilden(22)">
          <div
            :class="
              textEffect ? 'introduce-item-icon slide-in-right' : 'disnone'
            "
          >
            <img src="@/assets/image/home/gszz.png" alt="" />
          </div>
          <div
            :class="
              textEffect ? 'introduce-item-text slide-in-right' : 'disnone'
            "
          >
            <div class="introduce-item-text-z">公司资质</div>
            <div class="introduce-item-text-e">COMPANY QUALIFICATION</div>
          </div>
        </div>
        <div class="introduce-item" @click="getOpenItemChilden(23)">
          <div
            :class="
              textEffect ? 'introduce-item-icon  slide-in-right' : 'disnone'
            "
          >
            <img src="@/assets/image/home/gswdkh.png" alt="" />
          </div>
          <div
            :class="
              textEffect ? 'introduce-item-text slide-in-right' : 'disnone'
            "
          >
            <div class="introduce-item-text-z">我们的客户</div>
            <div class="introduce-item-text-e">OUR CLIENTS</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      textEffect: false,
      textP: false,
      //   childen: [
      //         {
      //           id: 21,
      //           name: "公司介绍",
      //         },
      //         {
      //           id: 22,
      //           name: "公司资质",
      //         },
      //         {
      //           id: 23,
      //           name: "我们的客户",
      //         },
      //       ],
    };
  },
  methods: {
    getMoreText(e) {
      this.textP = e;
    },
    getOpenItemChilden(id) {
      this.itemChildenOpen = id;
      sessionStorage.setItem("itemChildenOpen", this.itemChildenOpen);
      this.clickListId = 1;
      sessionStorage.setItem("clickListId", this.clickListId);
      this.$router.push({
        name: "aboutUs",
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.disnone {
  display: none;
}

.textMore {
  color: #0063c9;
  cursor: pointer;
  font-size: 18px;
  /* padding-bottom: 2px; */
  border-bottom: 1px solid #0063c9;
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.8s;
  animation: slide-in-right 0.8s;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.title {
  width: 100%;
  margin-top: 80px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 80px;
  margin-top: 0px;
}
.title-t {
  color: #333;
  font-size: 26px;
  margin-bottom: 16px;
  position: relative;
  font-weight: bold;
  background: #fff;
  overflow: hidden;
}
.title-t::before,
.title-t::after {
  content: "";
  position: absolute;
  top: 100px;
  left: 50%;
  width: 180px;
  height: 180px;
  border-radius: 25% 65% 43% 47%;
  transform: translate(-50%, -50%);
  background: rgba(3, 169, 244, 0.85);
  animation: rotate 10s infinite linear;
  z-index: 1;
  mix-blend-mode: lighten;
}
.title-t::after {
  border-radius: 43% 47% 44% 48%;
  animation: rotate 10s infinite 0.5s linear;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.title-b {
  width: 60px;
  height: 2px;
  background-color: rgb(44, 134, 237);
}

.introduce {
  width: 100%;
  overflow: hidden;
}

.introduce-text {
  width: calc(100% - 32px);
  overflow: hidden;
  padding: 0 16px;
  padding-bottom: 32px;
}
.introduce-text p {
  font-size: 16px;
  color: #333;
  text-indent: 2em;
  line-height: 30px;
}

.introduce-geto {
  width: 90%;
  display: flex;
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.introduce-item {
  width: 240px;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px #cccccc;
  margin-bottom: 32px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
}

.introduce-item::after,
.introduce-item::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  left: 0;
  box-sizing: border-box;
  /* transition: width .5s, height .5s;
 transition-delay: .5s, 0s; */
  border: 3px solid transparent;
}

.introduce-item:hover {
  color: #00e2ff;
  border-radius: 8px;
}

.introduce-item:hover:before {
  transition: width 0.3s, height 0.3s, border-bottom-color 0s;
  transition-delay: 0.3s, 0s, 0.3s;
  width: 240px;
  height: 240px;
  border-left: 3px solid #076ce0;
  border-bottom: 3px solid #076ce0;
  border-radius: 8px;
}

.introduce-item:hover:after {
  transition: width 0.3s, height 0.3s, border-right-color 0.3s;
  transition-delay: 0s, 0.3s, 0.3s;
  width: 240px;
  height: 240px;
  border-top: 3px solid #076ce0;
  border-right: 3px solid #076ce0;
  border-radius: 8px;
}

.introduce-item-icon {
  width: 100px;
  height: 100px;
}
.introduce-item-icon img {
  width: 100%;
  height: 100%;
}

.introduce-item-text {
  color: #0063c9;
  text-align: center;
  padding-top: 16px;
}
.introduce-item-text-z {
  font-size: 24px;
}
.introduce-item-text-e {
  font-size: 16px;
  line-height: 30px;
  color: #0064c9a8;
}
</style>