<template>
  <div class="moduleFour">
    <!-- 标题 -->
    <div class="title">
      <div class="title-t">解决方案</div>
      <div class="title-b"></div>
    </div>
    <div class="solution">
      <div class="solution-item">
        <div
          @click="getOpenItemChilden(51)"
          :class="textEffect ? 'solution-item-i slide-in-right' : 'disnone'"
        >
          <img src="@/assets/image/home/show1.png" alt="" />
          <div class="solution-item-z">
            <img
              class="solution-item-z-img"
              src="@/assets/image/home/into.png"
              alt=""
            />
          </div>
        </div>
        <div
          :class="textEffect ? 'solution-item-t slide-in-rightout3' : 'disnone'"
        >
          数字化校园云平台
        </div>
      </div>
      <div class="solution-item">
        <div
          @click="getOpenItemChilden(52)"
          :class="textEffect ? 'solution-item-i slide-in-right ' : 'disnone'"
        >
          <img src="@/assets/image/home/show2.png" alt="" />
          <div class="solution-item-z">
            <img
              class="solution-item-z-img"
              src="@/assets/image/home/into.png"
              alt=""
            />
          </div>
        </div>
        <div
          :class="textEffect ? 'solution-item-t slide-in-rightout3' : 'disnone'"
        >
          智慧乡镇平台
        </div>
      </div>
      <div class="solution-item">
        <div
          @click="getOpenItemChilden(53)"
          :class="textEffect ? 'solution-item-i slide-in-right ' : 'disnone'"
        >
          <img src="@/assets/image/home/show3.png" alt="" />
          <div class="solution-item-z">
            <img
              class="solution-item-z-img"
              src="@/assets/image/home/into.png"
              alt=""
            />
          </div>
        </div>
        <div
          :class="textEffect ? 'solution-item-t slide-in-rightout3' : 'disnone'"
        >
          智慧城市网格化平台
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      textEffect: false,
    };
  },
  methods: {
    getOpenItemChilden(id) {
      this.itemChildenOpen = id;
      sessionStorage.setItem("itemChildenOpen", this.itemChildenOpen);
      this.clickListId = 4;
      sessionStorage.setItem("clickListId", this.clickListId);
      this.$router.push({
        name: "solution",
      });
    },
  },
};
</script>

<style scoped>
.disnone {
  display: none;
}

.slide-in-rightout3 {
  -webkit-animation: slide-in-right 1s;
  animation: slide-in-right 1s;
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.8s;
  animation: slide-in-right 0.8s;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes flip-in-hor-top {
  0% {
    -webkit-transform: rotateX(-80deg);
    transform: rotateX(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-top {
  0% {
    -webkit-transform: rotateX(-80deg);
    transform: rotateX(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}

.title {
  width: 100%;
  margin-top: 80px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 80px;
  margin-top: 0px;
}
.title-t {
  color: #333;
  font-size: 26px;
  margin-bottom: 16px;
  position: relative;
  font-weight: bold;
  background: #fff;
  overflow: hidden;
}
.title-t::before,
.title-t::after {
  content: "";
  position: absolute;
  top: 100px;
  left: 50%;
  width: 180px;
  height: 180px;
  border-radius: 25% 65% 43% 47%;
  transform: translate(-50%, -50%);
  background: rgba(3, 169, 244, 0.85);
  animation: rotate 10s infinite linear;
  z-index: 1;
  mix-blend-mode: lighten;
}
.title-t::after {
  border-radius: 43% 47% 44% 48%;
  animation: rotate 10s infinite 0.5s linear;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.title-b {
  width: 60px;
  height: 2px;
  background-color: rgb(44, 134, 237);
}
.solution {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 80px;
}

.solution-item {
  width: calc(340px - 32px);
  height: 240px;
  box-shadow: 0 0 10px #ccc;
  border-radius: 4px;
  padding: 16px;
  margin: 16px;
}
.solution-item-i {
  width: 100%;
  height: 200px;
  position: relative;
}
.solution-item-i:hover .solution-item-z {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-animation: flip-in-hor-top 1.2s;
  animation: flip-in-hor-top 1.2s;
}
.solution-item-z {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid #ccc;
  display: none;
}
.solution-item-z-img {
  width: 80px !important;
  height: 80px !important;
  display: block;
}

.solution-item-i img {
  width: 100%;
  height: 100%;
  display: block;
}
.solution-item-t {
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #333;
  padding-top: 16px;
}
</style>