<template>
  <div>
    <navbar
      ref="navbar"
      @getScreenWidths="getScreenWidths"
      @getScrollView="getScrollView"
    ></navbar>

    <div
      class="container"
      :style="screenWidths > 820 ? '' : 'padding-top:40px;'"
    >
      <!-- 首页横幅 -->
      <div class="banner">
        <el-carousel
          :interval="2000"
          trigger="click"
          :height="bannerHeight + 'px'"
        >
          <el-carousel-item v-for="item in banner" :key="item.url">
            <img ref="bannerItem" class="bannerItem" :src="item.url" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <el-row style="margin: 0; display: flex; flex-wrap: wrap">
        <el-col
          :xs="{ span: 24, offset: 0 }"
          :sm="{ span: 24, offset: 0 }"
          :md="{ span: 24, offset: 0 }"
          :lg="{ span: 20, offset: 2 }"
          :xl="{ span: 16, offset: 4 }"
        >
          <!-- 模块一：优质的服务 -->
          <div class="paddmgg" ref="moduleOneS">
            <module-one ref="moduleOne"></module-one>
          </div>

          <!-- 模块二： 我们的价值 -->
          <div class="paddmgg" ref="moduleTwoS">
            <module-two ref="moduleTwo"></module-two>
          </div>

          <!-- 模块三：我们的优势 -->
          <div class="paddmgg" ref="moduleThreeS">
            <module-three ref="moduleThree"></module-three>
          </div>

          <!-- 模块四：行业解决方案 -->
          <div class="paddmgg" ref="moduleFourS">
            <module-four ref="moduleFour"></module-four>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/display.css";

import rzy_banner1 from "@/assets/image/home/rzy_banner1.png";
import rzy_banner2 from "@/assets/image/home/rzy_banner2.png";
import rzy_banner3 from "@/assets/image/home/rzy_banner3.png";

import moduleOne from "./module/moduleOne.vue";
import moduleTwo from "./module/moduleTwo.vue";
import ModuleThree from "./module/moduleThree.vue";
import ModuleFour from "./module/moduleFour.vue";

import navbar from "../../components/navbar.vue";

export default {
  components: {
    navbar,
    moduleOne,
    moduleTwo,
    ModuleThree,
    ModuleFour,
  },
  data() {
    return {
      banner: [
        {
          url: rzy_banner1,
        },
        {
          url: rzy_banner2,
        },
        {
          url: rzy_banner3,
        },
      ],
      bannerHeight: 800,
      screenWidths: 0,
    };
  },
  computed: {},
  created() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.bannerHeight = this.$refs.bannerItem[0].offsetHeight;
        if (this.screenWidths < 820) {
          this.$refs.moduleOne.textEffect = true;
          this.$refs.moduleTwo.textEffect = true;
          this.$refs.moduleThree.textEffect = true;
          this.$refs.moduleFour.textEffect = true;
        }
      }, 200);
    });
  },
  mounted() {
    let _this = this;
    _this.$nextTick(function () {
      window.addEventListener("resize", this.resize);
      window.addEventListener("scroll", _this.onScroll);
    });
  },
  methods: {
    getScreenWidths(val) {
      this.screenWidths = val;
    },
    // 滚动条
    getScrollView(id) {
      console.log("getScrollView", id);
      if (id == 11) {
        this.$refs.moduleOneS.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      } else if (id == 12) {
        this.$refs.moduleTwoS.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      } else if (id == 13) {
        this.$refs.moduleThreeS.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      } else if (id == 14) {
        this.$refs.moduleFourS.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    },

    onScroll() {
      let scrolled =
        document.documentElement.scrollTop || document.body.scrollTop; // 586、1063分别为第二个和第三个锚点对应的距离
      // console.log(scrolled);
      if (scrolled >= 400 && this.screenWidths > 820) {
        this.$refs.moduleOne.textEffect = true;
        this.$refs.moduleTwo.textEffect = true;
        this.$refs.moduleThree.textEffect = true;
        this.$refs.moduleFour.textEffect = true;
      }
      

      // if (scrolled >= 750) {
      //   this.$refs.moduleTwo.textEffect = true;
      // }
      // if (scrolled >= 1095) {
      //   this.$refs.moduleThree.textEffect = true;
      // }
      // if (scrolled >= 1200) {
      //   this.$refs.moduleFour.textEffect = true;
      // }
    },
    resize() {
      let _this = this;
      return (() => {
        // window.screenWidths = document.body.clientWidth//获取页面装口宽度
        // _this.screenWidths = window.screenWidths//赋值screenWidths
        // console.log('screenWidths',_this.screenWidths)
        _this.bannerHeight = _this.$refs.bannerItem[0].offsetHeight;
      })();
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.resize);
  },
};
</script>

<style  scoped>

/* 轮播图 */
.banner {
  width: 100%;
  overflow: hidden;
}

.bannerItem {
  width: 100%;
}

</style>