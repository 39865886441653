<template>
  <div class="moduleThree">
    <!-- 标题 -->
    <div class="title">
      <div class="title-t">人员驻点</div>
      <div class="title-b"></div>
    </div>

    <div class="personnelAssign">
      <div @click="getOpenItemChilden(41)" :class=" textEffect ? 'personnelAssign-item bounce-in-left' : 'disnone' ">
        <div class="personnelAssign-item-t">人员驻点</div>
        <div class="personnelAssign-item-z">
          <img src="@/assets/image/home/into.png" alt="" />
        </div>
      </div>
      <div @click="getOpenItemChilden(43)" :class=" textEffect ?  'personnelAssign-item bounce-in-right' : 'disnone'">
        <div class="personnelAssign-item-t">合作伙伴</div>
        <div class="personnelAssign-item-z">
          <img src="@/assets/image/home/into.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      textEffect:false
    };
  },
   methods: {
    getOpenItemChilden(id) {
      this.itemChildenOpen = id;
      sessionStorage.setItem("itemChildenOpen", this.itemChildenOpen);
      this.clickListId = 3;
      sessionStorage.setItem("clickListId", this.clickListId);
      this.$router.push({
        name: "personnelAssignment",
      });
    },
  },
};
</script>

<style scoped>
.disnone {
  display: none;
}
.bounce-in-left {
  -webkit-animation: bounce-in-left 1.1s both;
  animation: bounce-in-left 1.1s both;
}

@-webkit-keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-600px);
    transform: translateX(-600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(-68px);
    transform: translateX(-68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(-28px);
    transform: translateX(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-600px);
    transform: translateX(-600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(-68px);
    transform: translateX(-68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(-28px);
    transform: translateX(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
.bounce-in-right {
  -webkit-animation: bounce-in-right 1.1s both;
  animation: bounce-in-right 1.1s both;
}

@-webkit-keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
            transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(68px);
            transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(32px);
            transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
            transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(68px);
            transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(32px);
            transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}


.title {
  width: 100%;
  margin-top: 80px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
      padding-top: 80px;
  margin-top: 0px;
}
.title-t {
  color: #333;
  font-size: 26px;
  margin-bottom: 16px;
  position: relative;
  font-weight: bold;
  background: #fff;
  overflow: hidden;
}
.title-t::before,
.title-t::after {
  content: "";
  position: absolute;
  top: 100px;
  left: 50%;
  width: 180px;
  height: 180px;
  border-radius: 25% 65% 43% 47%;
  transform: translate(-50%, -50%);
  background: rgba(3, 169, 244, 0.85);
  animation: rotate 10s infinite linear;
  z-index: 1;
  mix-blend-mode: lighten;
}
.title-t::after {
  border-radius: 43% 47% 44% 48%;
  animation: rotate 10s infinite 0.5s linear;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.title-b {
  width: 60px;
  height: 2px;
  background-color: rgb(44, 134, 237);
}

/* personnelAssign */
.personnelAssign {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.personnelAssign-item {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 10px #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin: 16px;
}

.personnelAssign-item:hover .personnelAssign-item-z {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.personnelAssign-item-t {
  text-align: center;
  width: 70%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #333;
  letter-spacing: 8px;
  line-height: 30px;
  word-wrap: wrap;
}

.personnelAssign-item-z {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(220, 220, 220, 0.6);
  display: none;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-1-21 10:54:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-fwd
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.personnelAssign-item-z img {
  width: 50px;
  height: 50px;
  z-index: 99;
  -webkit-animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
</style>