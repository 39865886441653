<template>
  <div class="moduleTwo">
    <!-- 标题 -->
    <div class="title">
      <div class="title-t">软件外包</div>
      <div class="title-b"></div>
    </div>
    <!-- 内容 -->
    <div class="software">
      <div class="software-item" @click="getOpenItemChilden(32)">
        <!-- 头 -->
        <div class="software-item-left"></div>
        <!-- 文字 -->
        <div
          :class="
            textEffect ? 'software-item-content slide-in-right' : 'disnone'
          "
        >
          通用框架模式
        </div>
        <!-- 进入标志 -->
        <div
          :class="textEffect ? 'software-item-icon slide-in-right' : 'disnone'"
        >
          <img src="@/assets/image/home/into.png" alt="" />
        </div>
      </div>
      <div class="software-item" @click="getOpenItemChilden(31)">
        <!-- 头 -->
        <div class="software-item-left"></div>
        <!-- 文字 -->
        <div
          :class="
            textEffect ? 'software-item-content slide-in-right' : 'disnone'
          "
        >
          特定平台模式
        </div>
        <!-- 进入标志 -->
        <div
          :class="textEffect ? 'software-item-icon slide-in-right' : 'disnone'"
        >
          <img src="@/assets/image/home/into.png" alt="" />
        </div>
      </div>
      <div class="software-item" @click="getOpenItemChilden(33)">
        <!-- 头 -->
        <div class="software-item-left"></div>
        <!-- 文字 -->
        <div
          :class="
            textEffect ? 'software-item-content slide-in-right' : 'disnone'
          "
        >
          项目管理流程
        </div>
        <!-- 进入标志 -->
        <div
          :class="textEffect ? 'software-item-icon slide-in-right' : 'disnone'"
        >
          <img src="@/assets/image/home/into.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      textEffect: false,
    };
  },
  methods: {
    getOpenItemChilden(id) {
      this.itemChildenOpen = id;
      sessionStorage.setItem("itemChildenOpen", this.itemChildenOpen);
      this.clickListId = 2;
      sessionStorage.setItem("clickListId", this.clickListId);
      this.$router.push({
        name: "softwareOutsourc",
      });
    },
  },
};
</script>

<style scoped>
.disnone {
  display: none;
}

.software-item:hover .software-item-content {
  transform: translateX(20px);
  color: rgb(44, 134, 237);
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.8s;
  animation: slide-in-right 0.8s;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.title {
  width: 100%;
  margin-top: 80px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
    padding-top: 80px;
   margin-top: 0px;
}
.title-t {
  color: #333;
  font-size: 26px;
  margin-bottom: 16px;
  position: relative;
  font-weight: bold;
  background: #fff;
  overflow: hidden;
}
.title-t::before,
.title-t::after {
  content: "";
  position: absolute;
  top: 100px;
  left: 50%;
  width: 180px;
  height: 180px;
  border-radius: 25% 65% 43% 47%;
  transform: translate(-50%, -50%);
  background: rgba(3, 169, 244, 0.85);
  animation: rotate 10s infinite linear;
  z-index: 1;
  mix-blend-mode: lighten;
}
.title-t::after {
  border-radius: 43% 47% 44% 48%;
  animation: rotate 10s infinite 0.5s linear;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.title-b {
  width: 60px;
  height: 2px;
  background-color: rgb(44, 134, 237);
}

.software {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.software-item {
  width: 300px;
  height: 50px;
  box-shadow: 0 0 10px #cccccc;
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin: 16px;
  cursor: pointer;
  position: relative;
  background-color: #fff;
}

.software-item::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 5%;
  right: 5%;
  bottom: 0;
  border-radius: 4px;
  background: hsl(244, 48%, 43%);
  transform: translate(1%, -20%) rotate(-3.5deg);
  transform-origin: center center;
  box-shadow: 0 0 10px 8px hsl(244, 48%, 43%);
  z-index: -1;
}

.software-item-left {
  height: 100%;
  width: 16px;
  background-color: rgb(44, 134, 237);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.software-item-content {
  flex: 1;
  height: 100%;
  font-size: 24px;
  letter-spacing: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s;
  -webkit-transition: transform 0.6s;
}

.software-item-icon {
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.software-item-icon img {
  width: 40px;
  height: 40px;
}
</style>